import { combineReducers } from '@reduxjs/toolkit'
import products from './productSlice'
import { UserStateInterface } from '../../../@types/auth';
const reducer = combineReducers({
    products,
})

export type ProductState = {
    products: UserStateInterface
}

export * from './productSlice'

export default reducer
