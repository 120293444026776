import { combineReducers } from '@reduxjs/toolkit'
import session, { SessionState } from './sessionSlice'
import user from './userSlice'
import { UserStateInterface } from '../../../@types/auth';
const reducer = combineReducers({
    session,
    user,
})

export type AuthState = {
    session: SessionState
    user: UserStateInterface
}

export * from './sessionSlice'
export * from './userSlice'

export default reducer
