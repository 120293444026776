import ApiService from './ApiService'
import type {
    SignInCredential,
    SignUpCredential,
    ForgotPassword,
    ResetPassword,
    SignInResponse,
    SignUpResponse,
    ForgotResponse,
    GetVideoCredential,
    GetVideoResponse,
    GetShortsResponse,
    GetVideosResponse,
    VerifyEmail,
} from '@/@types/auth'

export async function apiSignIn(data: SignInCredential) {
    return ApiService.fetchData<SignInResponse>({
        url: '/users/login',
        method: 'post',
        data,
    })
}


export async function apiSignInWithToken(data: SignInCredential) {
    return ApiService.fetchData<any>({
        url: '/users/loginWithToken',
        method: 'post',
        headers: {
            Authorization: `Bearer ${data.token}`
        }
    })
}

export async function apiSignInWithTokenLifeTimeAccess(data: SignInCredential) {
    return ApiService.fetchData<any>({
        url: `/users/loginWithToken?lifeTimeAccess=${data.lifeTimeAccess}`,
        method: 'post',
        headers: {
            Authorization: `Bearer ${data.token}`
        }
    })
}

export async function apiSignUp(data: SignUpCredential) {
    return ApiService.fetchData<SignUpResponse>({
        url: '/users/register',
        method: 'post',
        data,
    })
}

export async function apiSignOut() {
    return ApiService.fetchData({
        url: '/users/logout',
        method: 'post',
    })
}

export async function apiForgotPassword(data: ForgotPassword) {
    return ApiService.fetchData<ForgotResponse>({
        url: '/users/forgot-password',
        method: 'post',
        data,
    })
}
export async function verifyEmail(data : VerifyEmail) {
    return ApiService.fetchData<ForgotResponse>({
        url: '/users/verification-email',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data: ResetPassword) {
    return ApiService.fetchData({
        url: '/users/reset-password',
        method: 'post',
        data,
    })
}

export async function apiGetVideo(data: GetVideoCredential) {
    return ApiService.fetchData<GetVideoResponse>({
        url: data.videoUrl,
        method: 'get'
    })
}

export async function apiGetAllYoutubeVideos() {
    return ApiService.fetchData<GetVideosResponse>({
        url: 'videos/youtube',
        method: 'get'
    })
}

export async function apiUploadShorts(data: GetVideoCredential) {
    return ApiService.fetchData<GetVideoResponse>({
        url: data.videoUrl,
        method: 'post',
        ...(data.data && {data: data?.data})
    })
}

