import Button from '@/components/ui/Button'
import { Alert, Dialog, Radio, Switcher, toast } from '@/components/ui'
import { FC, ReactNode, useEffect, useState } from 'react'
import {
    apiDeleteSubscription,
    apiGetPaymentProducts,
    apiPostPaymentSubscription,
} from '@/services/PaymentServices'
import { useAppSelector, useAppDispatch } from '@/store'
import { StripeProduct } from '@/@types/common'
import { TypeAttributes } from '@/components/ui/@types/common'
import PlanList from './PlanList'
import { TbArrowNarrowLeft, TbArrowNarrowUp } from 'react-icons/tb'
import { doGetProductsList } from '@/store/slices/products'

interface PricingDialogProps {
    buttonOnHeader?: boolean
    buttonText?: string
    buttonClassName?: string
    displayIcon?: boolean
    customIcon?: ReactNode
    className?: string
    variant?: 'default' | 'notEnoughCredits' | 'dropdown'
    creditsError?: { remainingCredits: number; requiredCredits: number }
    isOpen?: boolean
    setCloseDialog?: (value: boolean) => void
    showButton?: boolean
}

const PricingDialog: FC<PricingDialogProps> = ({
    buttonOnHeader,
    buttonText,
    buttonClassName,
    displayIcon = true,
    customIcon,
    className,
    variant = 'default',
    creditsError,
    isOpen = false,
    showButton = true,
    setCloseDialog,
}) => {
    const dispatch = useAppDispatch()
    const planId = useAppSelector((state) => state.auth.user?.planId)
    const products = useAppSelector((state) => state.products.products_list)
    const isLifetimeAccess = useAppSelector((state) => state.auth.user?.isLifetimeAccess)

    const [productList, setProductList] = useState<StripeProduct[]>([])

    const getStripeProducts = async () => {
        try {
            // const response: { data: StripeProduct[] } =
            //     await apiGetPaymentProducts()
           await dispatch(doGetProductsList())
            // setProductList(response?.payload)
        } catch (error) {
            toast.push(
                <Alert showIcon closable type="danger" rounded={false}>
                    {'Something went wrong'}
                </Alert>,
                {
                    offsetX: 0,
                    offsetY: 0,
                    transitionType: 'fade',
                    block: true,
                }
            )
        }
    }

    useEffect(() => {
        getStripeProducts()
    }, [])

    useEffect(() => {
        if(products) setProductList(products)
    }, [products])

    const lifeTimeProducts = productList?.filter(
        (product) => product.interval === 'Life Time'
    )
    const yearlyProducts = productList?.filter(
        (product) => product.interval === 'Yearly'
    )
    const monthlyProducts = productList?.filter(
        (product) => product.interval === 'Monthly'
    )

    const [pricingDialogOpen, setPricingDialogOpen] = useState<boolean>(false)
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
    const [cancelSuccess, setCancelSuccess] = useState(false)

    const [billingCycle, setBillingCycle] = useState(isLifetimeAccess ? 'lifeTime' : 'yearly')

    const handleSubscriptionPayment = async (
        planId: string,
        priceId: string
    ) => {
        try {
            const response: {
                statusText: string
                data: { redirectLink: string; message: string }
            } = await apiPostPaymentSubscription({
                planId,
                priceId,
                billingCycle,
            })
            toast.push(
                <Alert showIcon closable type="success" rounded={false}>
                    {response.data.message}
                </Alert>,
                {
                    offsetX: 0,
                    offsetY: 0,
                    transitionType: 'fade',
                    block: true,
                }
            )
            window.location.href = response.data?.redirectLink
        } catch (error) {
            toast.push(
                <Alert showIcon closable type="danger" rounded={false}>
                    {'Something went wrong'}
                </Alert>,
                {
                    offsetX: 0,
                    offsetY: 0,
                    transitionType: 'fade',
                    block: true,
                }
            )
        }
    }

    const handleSubscriptionCancel = async () => {
        try {
            const response: {
                data: { status: TypeAttributes.Status; message: string }
            } = await apiDeleteSubscription()
            toast.push(
                <Alert
                    showIcon
                    closable
                    type={response?.data.status}
                    rounded={false}
                >
                    {response?.data.message}
                </Alert>,
                {
                    offsetX: 0,
                    offsetY: 0,
                    transitionType: 'fade',
                    block: true,
                }
            )
            if (response.data.status === 'success') {
                setCancelSuccess(true)
            }
        } catch (error) {
            toast.push(
                <Alert showIcon closable type="danger" rounded={false}>
                    {'Something went wrong'}
                </Alert>,
                {
                    offsetX: 0,
                    offsetY: 0,
                    transitionType: 'fade',
                    block: true,
                }
            )
        } finally {
            setCancelDialogOpen(false)
        }
    }
    let billingProducts;

    switch (billingCycle) {
        case 'yearly':
            billingProducts = yearlyProducts;
            break;
        case 'lifeTime':
            billingProducts = lifeTimeProducts;
            break;
        case 'monthly':
        default:
            billingProducts = monthlyProducts;
            break;
    }
        
    useEffect(() => {
        if (isOpen) setPricingDialogOpen(true)
    }, [isOpen])

    const filteredLifeTimePlan = lifeTimeProducts?.some((v) => v.productId === planId);
    const filteredYearlyPlan = yearlyProducts?.some((v) => v.productId === planId);
    const filteredMontlyPlan = monthlyProducts?.some((v) => v.productId === planId);

    useEffect(() => {
        if(isLifetimeAccess){
            setBillingCycle('lifeTime')
        }
        else if ( planId) {
            if (filteredLifeTimePlan) {
                setBillingCycle('lifeTime')
            } else if (filteredYearlyPlan) {
                setBillingCycle('yearly')
            } else if (filteredMontlyPlan) {
                setBillingCycle('monthly')
            }
        }
    }, [filteredYearlyPlan, filteredMontlyPlan, filteredLifeTimePlan, planId])


    return (
        <div className={className}>
            {showButton && (variant == 'default' ? (
                <Button
                    className={`${
                        buttonClassName ||
                        (buttonOnHeader
                            ? 'text-xs font-semibold dark:!text-primary-600'
                            : 'mt-6 flex flex-row gap-1 items-center !text-white w-full justify-center')
                    }`}
                    variant={`${buttonOnHeader ? 'plain' : 'primary'}`}
                    size={`${buttonOnHeader ? 'sm' : 'md'}`}
                    icon={
                        customIcon ||
                        (displayIcon && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-crown"
                            >
                                <path d="m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14" />
                            </svg>
                        ))
                    }
                    onClick={() => setPricingDialogOpen(true)}
                >
                    {buttonText || 'Upgrade'}
                </Button>
            ) : variant == 'dropdown' ? (
                <div
                    className={`${buttonClassName} flex flex-row gap-2 items-center !text-sm w-32 py-2`}
                    onClick={() => setPricingDialogOpen(true)}
                >
                    {buttonText || null}
                    {displayIcon && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-crown"
                        >
                            <path d="m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14" />
                        </svg>
                    )}
                </div>
            ) : null)}

            <Dialog
                isOpen={pricingDialogOpen}
                onClose={() => {
                    setPricingDialogOpen(false)
                    setCloseDialog?.(false)
                }}
                onRequestClose={() => {
                    setPricingDialogOpen(false)
                    setCloseDialog?.(false)
                }}
                width={'100%'}
                // bodyOpenClassName="overflow-hidden"
            >
                <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-2">
                        <h5>
                            {variant === 'notEnoughCredits'
                                ? `You Don't Have Enough Credits`
                                : 'Upgrade Your Plan'}
                        </h5>
                        <span className="text-gray-500 text-base">
                            {variant === 'notEnoughCredits'
                                ? `You are trying to generate clips from ${creditsError?.requiredCredits} minutes video but you only left with credits for ${creditsError?.remainingCredits} minutes, please upgrade your plan to continue using Voodoo`
                                : `It's time to automate your social media creation
                            cycle!`}
                        </span>
                    </div>
                    <div className="flex flex-row gap-2 justify-center">
                    {isLifetimeAccess && 
                        <p >Pay once, Use forever</p>
                    }
                    </div>
                    {!isLifetimeAccess && <div className="w-full flex gap-2 items-center justify-center relative mb-10 lg:mb-0">
                        <div className="absolute left-[56%] top-[-50px] lg:left-[63%] lg:top-0.5 xl:left-[60%] xl:top-0.5">
                            {/* Desktop Text with Flowing Icon */}
                            <div className="hidden lg:flex items-center space-x-1">
                                <div className="relative w-6 h-4 overflow-hidden">
                                    <div className="absolute flex items-center animate-flow-infinite">
                                        <TbArrowNarrowLeft className="text-gray-600" />
                                    </div>
                                </div>
                                <p className="text-base text-gray-600 select-none">
                                    2 Months Free!
                                </p>
                            </div>

                            {/* Mobile Text with Flowing Icon */}
                            <div className="flex lg:hidden mt-24 flex-col items-center space-y-1">
                                <div className="relative w-8 h-2 overflow-hidden">
                                    <div className="absolute flex items-center animate-flow-infinite-mobile">
                                        <TbArrowNarrowUp className="w-4 h-4 text-gray-600" />
                                    </div>
                                </div>
                                <p className="text-base text-gray-600 select-none">
                                    2 Months Free!
                                </p>
                            </div>
                        </div>
                        {/* <Radio.Group value={billingCycle} onChange={(val) => setBillingCycle(val)}>
                            <Radio key={'monthly'} value={'monthly'}>
                                <h5 className="select-none">Monthly</h5>
                            </Radio>
                            <Radio key={'c'} value={'yearly'}>
                                <h5 className="select-none">Yearly</h5>
                            </Radio>
                            <Radio key={'lifeTime'} value={'lifeTime'}>
                                <h5 className="select-none">Life Time</h5>
                            </Radio>
                        </Radio.Group> */}

                        <h5 className="select-none">Monthly</h5>
                        <Switcher
                            className="w-8"
                            onChange={(e) =>
                                setBillingCycle(e ? 'yearly' : 'monthly')
                            }
                            defaultChecked={billingCycle === 'yearly'}
                        />
                        <h5 className="select-none">Yearly</h5>
                    </div>}
                    <PlanList
                        billingProducts={billingProducts}
                        cancelSuccess={cancelSuccess}
                        planId={planId ?? ''}
                        handleSubscriptionPayment={handleSubscriptionPayment}
                    />
                </div>
            </Dialog>
            <Dialog
                isOpen={cancelDialogOpen}
                onClose={() => setCancelDialogOpen(false)}
                onRequestClose={() => setCancelDialogOpen(false)}
            >
                <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-2">
                        <h5 className="pb-4 text-center">
                            Cancel Subscription
                        </h5>
                        <span className="text-gray-500 text-base">
                            Are you sure you want to cancel your subscription?
                            This action is permanent and can not be undone. All
                            associated benefits of the affiliation will be lost.
                        </span>
                    </div>
                    <div className="flex flex-row gap-2 justify-center">
                        <Button
                            size="md"
                            variant="primary"
                            className="bg-red-500 hover:bg-red-400 active:bg-red-400"
                            onClick={() => {
                                handleSubscriptionCancel()
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default PricingDialog
