import { apiSignIn, apiSignInWithToken, apiSignInWithTokenLifeTimeAccess, apiSignOut, apiSignUp } from '@/services/AuthService'
import {
    setUser,
    signInSuccess,
    signOutSuccess,
    useAppSelector,
    useAppDispatch,
    clearTemplateList,
} from '@/store'
import appConfig from '@/configs/app.config'
import { REDIRECT_URL_KEY } from '@/constants/app.constant'
import { useLocation, useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import type { SignInCredential, SignUpCredential } from '@/@types/auth'
import useGenerateVideo from './useGenerateVideo'

type Status = 'success' | 'failed'

function useAuth() {
    const dispatch = useAppDispatch()

    const navigate = useNavigate()
    const location = useLocation()

    const query = useQuery()

    const { token, signedIn } = useAppSelector((state) => state.auth.session)
    
    const storedVideoId = localStorage.getItem("videoUrl")


    const signIn = async (
        values: any
    ): Promise<
        | {
            status: Status
            message: string
        }
        | undefined
    > => {
        try {
            let resp;
            if (values.token) {
                if(values.lifeTimeAccess){
                    resp = await apiSignInWithTokenLifeTimeAccess(values)
                }
                else{
                    resp = await apiSignInWithToken(values)
                }
            } else {
                resp = await apiSignIn(values)
            }
            if (resp.data) {
                const { token, user: userRes, redirectLink } = resp.data
                dispatch(signInSuccess(token))
                const user = {
                    avatar: '',
                    userName: '',
                    authority: ['admin'],
                    email: values.email,
                    ...userRes
                }
                dispatch(
                    setUser(
                        user || {
                            avatar: '',
                            userName: 'Anonymous',
                            authority: ['USER'],
                            email: '',
                        }
                    )
                )
                if(redirectLink){
                    window.location.href = redirectLink
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                if (storedVideoId) {
                    useGenerateVideo()
                } else if ( !(location.pathname.includes('canceled') || location.pathname.includes('success')) ) {
                    navigate(
                        redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                    )
                }
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors: any) {
            return {
                status: 'failed',
                message: errors?.response?.data?.error || errors.toString(),
            }
        }
    }

    const signUp = async (values: SignUpCredential) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                return {
                    status: 'success',
                    message: resp.data.message,
                }
            }
        } catch (errors: any) {
            return {
                status: 'failed',
                message: errors?.response?.data?.error || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(signOutSuccess())
        dispatch(
            setUser({
                avatar: '',
                userName: '',
                email: '',
                authority: [],
            })
        )
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
        dispatch(clearTemplateList());
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
