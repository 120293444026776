import { useState, useCallback, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { Popper, Reference, Manager } from 'react-popper'
import { motion, AnimatePresence } from 'framer-motion'
import Arrow from './Arrow'
import { Portal } from 'react-portal'
import type { CommonProps } from '../@types/common'
import type { ArrowPlacement } from './Arrow'
import type { State as PopperJsState } from '@popperjs/core'
import type { ReactNode } from 'react'

export interface TooltipProps extends CommonProps {
    isOpen?: boolean
    interactive?: boolean
    placement?: ArrowPlacement
    title: string | ReactNode
    wrapperClass?: string
}

const PopperElement = (props: {
    title: string | ReactNode
    open: boolean
    forceUpdate: () => Partial<PopperJsState>
}) => {
    const { title, forceUpdate, open } = props
    useEffect(() => {
        if (open) {
            forceUpdate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])
    return <span>{title}</span>
}

const Tooltip = (props: TooltipProps) => {
    const {
        className,
        children,
        isOpen = false,
        interactive = false,
        placement = 'top',
        title,
        wrapperClass,
        ...rest
    } = props

    const tooltipNode = useRef<any>(null)
    const triggerNode = useRef<any>(null)

    const tooltipBackground = 'gray-800'
    const tooltipDarkBackground = 'black'

    let defaultTooltipClass = `tooltip bg-${tooltipBackground} dark:bg-${tooltipDarkBackground}`

    if (!interactive) {
        defaultTooltipClass += ' !pointer-events-none'
    } else {
        defaultTooltipClass += ' !pointer-events-auto'
    }

    const [isVisible, setIsVisible] = useState<boolean>(isOpen)

    const handleMouseEnter = () => {
        setIsVisible(true)
    }

    const handleMouseLeave = (event: any) => {
        if (interactive) {
            const { relatedTarget } = event
            if (
                tooltipNode.current &&
                (tooltipNode.current.contains(relatedTarget) ||
                    triggerNode.current && triggerNode.current.contains(relatedTarget))
            ) {
                return
            }
        }
        setIsVisible(false)
    }

    useEffect(() => {
        return () => {
            tooltipNode.current = null;
            triggerNode.current = null;
        };
    }, []);
    
    useEffect(() => {
        const handleDocumentClick = (event: any) => {
            if (
                tooltipNode.current &&
                !tooltipNode.current.contains(event.target) &&
                triggerNode.current &&
                !triggerNode.current.contains(event.target)
            ) {
                setIsVisible(false)
            }
        }

        if (interactive) {
            document.addEventListener('click', handleDocumentClick)
            return () => {
                document.removeEventListener('click', handleDocumentClick)
            }
        }
    }, [interactive])

    return (
        <Manager>
            <Reference>
                {({ ref }: any) => (
                    <span
                        ref={(node) => {
                            ref(node)
                            if (triggerNode.current) triggerNode.current = node
                        }}
                        className={classNames('tooltip-wrapper flex flex-grow', wrapperClass)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {children}
                    </span>
                )}
            </Reference>
            {isVisible && (
                <Portal>
                    <Popper
                        placement={placement}
                        innerRef={(node) => (tooltipNode.current = node)}
                        modifiers={[
                            {
                                name: 'arrow',
                                options: {
                                    element: Arrow as unknown as HTMLElement,
                                },
                            },
                            { name: 'offset', options: { offset: [0, 7] } },
                        ]}
                        strategy={'fixed'}
                    >
                        {({ ref, style, ...popperProps }) => (
                            <AnimatePresence>
                                <motion.div
                                    ref={ref}
                                    className={classNames(
                                        defaultTooltipClass,
                                        className
                                    )}
                                    style={style}
                                    initial={{
                                        opacity: 0,
                                        visibility: 'hidden',
                                    }}
                                    animate={{
                                        opacity: isVisible ? 1 : 0,
                                        visibility: isVisible
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                    transition={{
                                        duration: 0.15,
                                        type: 'tween',
                                    }}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <PopperElement
                                        open={isVisible}
                                        title={title}
                                        {...rest}
                                        {...popperProps}
                                    />
                                    <Arrow
                                        placement={placement}
                                        color={tooltipBackground}
                                        colorDark={tooltipDarkBackground}
                                    />
                                </motion.div>
                            </AnimatePresence>
                        )}
                    </Popper>
                </Portal>
            )}
        </Manager>
    )
}

Tooltip.displayName = 'Tooltip'

export default Tooltip
