import { StripeProduct } from '@/@types/common'
import Plan from './Plan'
import PlanSkeleton from './PlanSkeleton'

interface PlanListInterface {
    billingProducts: StripeProduct[]
    cancelSuccess: boolean
    planId: String
    handleSubscriptionPayment: (
        planId: string,
        priceId: string
    ) => Promise<void>
}

const PlanList = ({
    billingProducts,
    cancelSuccess,
    planId,
    handleSubscriptionPayment,
}: PlanListInterface) => {
    return (
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-2 justify-between">
            {billingProducts && billingProducts.length > 0 ? (
                billingProducts.map((product: StripeProduct, index: number) => (
                    <Plan
                        key={index}
                        product={product}
                        planId={cancelSuccess ? '' : planId ?? ''}
                        handleSubscriptionPayment={handleSubscriptionPayment}
                        billingProducts={billingProducts}
                    />
                ))
            ) : (
                <div className="flex flex-col lg:flex-row items-center justify-between h-[470px] w-full gap-4">
                    {Array.from({ length: 3 }).map((_, index) => (
                        <PlanSkeleton key={index} />
                    ))}
                </div>
            )}
        </div>
    )
}

export default PlanList
