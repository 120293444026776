import ApiService from './ApiService'

export async function apiGetProjectDashboardData<T>() {
    return ApiService.fetchData<T>({
        url: '/project/dashboard',
        method: 'get',
    })
}

export async function apiGetProjectList<T, U extends Record<string, unknown>>(
    data: U
) {
    return ApiService.fetchData<T>({
        url: '/videos',
        method: 'get',
        params: data,
    })
}

export async function apiGetStatisticsData<T>() {
    return ApiService.fetchData<T>({
        url: '/videos/detailStatistics',
        method: 'get',
    })
}

export async function apiGetShortList<T, U extends Record<string, unknown>>(
    data: U,
    url: string
) {
    return ApiService.fetchData<T>({
        url,
        method: 'get',
        params: data,
    })
}
export async function apiGetScheduleList<T>(
    url: string
) {
    return ApiService.fetchData<T>({
        url,
        method: 'get',
    })
}

export async function apiDeleteShort<T, U extends Record<string, unknown>>(
    data: any
) {
    return ApiService.fetchData<T>({
        url: `/shorts/${data._id || data.refShortId}`,
        method: 'delete',
        data
    })
}

export async function apiEditShort<T, U extends Record<string, unknown>>(
    data: U,
    options: { auto?: boolean } = {}
) {
    const { auto } = options
    const requestData = auto ? {auto:true} : data;
    return ApiService.fetchData<T>({
        url: `/shorts/${data._id}`,
        method: 'put',
        data: requestData,
    })
}

export async function editDbShort<T, U extends Record<string, unknown>>(
    data: U, 
) { 
    return ApiService.fetchData<T>({
        url: `/shorts/editShorts/${data._id}`,
        method: 'put',
        data: data,
    })
}


export async function apiPutProjectList<T, U extends Record<string, unknown>>(
    data: U
) {
    return ApiService.fetchData<T>({
        url: '/project/list/add',
        method: 'put',
        data,
    })
}
export async function getVideoDetailsById<T, U extends Record<string, unknown>>(
    data: U
) {
    return ApiService.fetchData<T>({
        url: `/videos/videoById/${data.id}`,
        method: 'get',
    })
}

export async function deleteVideoByIdApi<T, U extends Record<string, unknown>>(
    id: U
) {
    return ApiService.fetchData<T>({
        url: `/videos/videoById/${id}`,
        method: 'delete',
    })
}

export async function uploadVideoApi<T, U extends Record<string, unknown>>(
    data: U
) {
    return ApiService.fetchData<T>({
        url: `/videos/upload`,
        method: 'post',
        data
    })
}

export async function uploadVideoSignedUrlApi<T, U extends Record<string, unknown>>(
    data: U
) {
    return ApiService.fetchData<T>({
        url: `/videos/upload-signed-url`,
        method: 'post',
        data
    })
}

export async function apiGetScrumBoards<T>() {
    return ApiService.fetchData<T>({
        url: '/project/scrum-board/boards',
        method: 'post',
    })
}

export async function apiGetScrumBoardtMembers<T>() {
    return ApiService.fetchData<T>({
        url: '/project/scrum-board/members',
        method: 'post',
    })
}

export async function apiGetScrumBoardtTicketDetail<T>() {
    return ApiService.fetchData<T>({
        url: '/project/scrum-board/tickets/detail',
        method: 'get',
    })
}
