import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'
import { apiUpdateSettings } from '@/services/AccountServices'
import { apiGetTemplateDetails } from '@/services/AccountServices'
import { UserStateInterface, TemplateInterface } from '@/@types/auth'
import { IVideoDetails, VideoByIdRequest, VideoByIdResponse } from '@/views/videos/store'
import { getVideoDetailsById } from '@/services/ProjectService'

type Template = {
    name: string
    watermark: boolean
    captions: {
        isShowing: boolean
        title: string
        uppercase: boolean
        placement: string[]
        lines: number
        font: {
            family: string
            color: string
            stroke: {
                thickness: number
                color: string
            }
            size: string
        }
        highlightColor: string
        roundedRectangleBackgroundColor: string
    }
    id: string
    default: boolean
}


export type UserState = {
    avatar?: string
    userName?: string
    username?:string
    email?: string
    authority?: string[]
    _id?: string
    isAdmin: boolean
    isLifetimeAccess?: boolean
    planId?: String
    priceId?: String
    planExpiry?:Number
    facebookLogin: any
    googleLogin: any
    instaLogin: any
    tiktokLogin: any
    volume: number | null
    integrationSettings: {
        facebookLogin?: boolean
        instaLogin?: boolean
        tiktokLogin?: boolean
        googleLogin?: boolean
    }
    settings: {
        autoProcess: boolean
        autoPublish: boolean
        updatedStatus: string
        autoApproval: boolean
        scheduleDelay: number
        scheduleOptimizer: boolean
        notification: {
            autoApproval: boolean
            language: string | null
            scheduleDelay: number
            scheduleOptimizer: boolean
            syncData: boolean
            timeZone: string | null
            newsNotify: string[] | null
            accActivityNotifiy: string[] | null
            signInNotify: string[] | null
            remindersNotify: string[] | null
            createdEmail: string[] | null
            earlyEmail: string[] | null
            uploadedEmail: string[] | null
            assignedNotify: string[] | null
            newProductNotify: string[] | null
            newOrderNotify: string[] | null
        }
        templates: [
            {
                name: string
                watermark: boolean
                captions: {
                    isShowing: boolean
                    title: string
                    uppercase: boolean
                    placement: string
                    lines: number
                    font: {
                        family: string
                        color: string
                        stroke: {
                            thickness: number
                            color: string
                        }
                        size: string
                    }
                    highlightColor: string
                    roundedRectangleBackgroundColor: string
                }
                id: string
                default: boolean
            }
        ]
    }
    tempDetails: Template
    template_list_loaded: boolean
    template_list: any
    filteredPlan: any
    videoDetails: IVideoDetails
    videoDetailsLoading: boolean
}

export type SettingsType = {
    autoProcess: boolean
    autoPublish: boolean
    updatedStatus: string
}

const initialState: UserState = {
    avatar: '',
    userName: '',
    username: '',
    email: '',
    authority: [],
    _id: '',
    planId:"",
    priceId: "",
    planExpiry:0,
    isAdmin: false,
    isLifetimeAccess: false,
    facebookLogin: null,
    googleLogin: null,
    instaLogin: null,
    tiktokLogin: null,
    volume: null,
    integrationSettings: {
        facebookLogin: false,
        instaLogin: false,
        tiktokLogin: false,
        googleLogin: false,
    },
    settings: {
        autoPublish: false,
        autoProcess: false,
        updatedStatus: "",
        autoApproval: false,
        scheduleDelay: 0,
        scheduleOptimizer: false,
        notification: {
            autoApproval: false,
            language: '',
            scheduleDelay: 0,
            scheduleOptimizer: false,
            syncData: false,
            timeZone: '',
            newsNotify: [],
            accActivityNotifiy: [],
            signInNotify: [],
            remindersNotify: [],
            createdEmail: [],
            earlyEmail: [],
            uploadedEmail: [],
            assignedNotify: [],
            newProductNotify: [],
            newOrderNotify: [],
        },
        templates: [
            {
                name: '',
                watermark: false,
                captions: {
                    isShowing: false,
                    title: '',
                    uppercase: false,
                    placement: "",
                    lines: 0,
                    font: {
                        family: '',
                        color: '',
                        stroke: {
                            thickness: 0,
                            color: '',
                        },
                        size: '',
                    },
                    highlightColor: '',
                    roundedRectangleBackgroundColor: '',
                },
                id: '',
                default: false,
            },
        ],
    },
    tempDetails: {} as Template,
    template_list_loaded: false,
    template_list: null,
    filteredPlan: [],
    videoDetails: {
        provider: {
            _id: '',
            name: ''
        },
        _id: '',
        title: '',
        url: '',
        userId: '',
        thumbnail: '',
        progress: null,
        createdAt: '',
        __v: null
    },
    videoDetailsLoading: false
}

export const SLICE_NAME = 'users'


export const UpdateSettings = createAsyncThunk(
    SLICE_NAME + '/updateSettings',
    async (data: SettingsType) => {
        const response = await apiUpdateSettings(data)
        return response.data
    }
)

export const doGetTemplateById = createAsyncThunk(
    'user/template',
    async (id: any) => {
        const response = await apiGetTemplateDetails(id)
        return response.data
    }
)

export const getVideoById = createAsyncThunk(
    SLICE_NAME + '/getVideoById',
    async (data: VideoByIdRequest) => {
        const response = await getVideoDetailsById<
            VideoByIdResponse,
            VideoByIdRequest
        >(data)
        return response.data
    }
)

const userSlice = createSlice({
    name: `${SLICE_BASE_NAME}/user`,
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<any>) {
            state.avatar = action.payload?.avatar
            state.email = action.payload?.email
            state.userName = action.payload?.userName ?? action.payload?.username //TODO: need fix from backend
            state.authority = action.payload?.authority
            state._id = action.payload?._id
            state.planId = action.payload?.planId
            state.googleLogin = action.payload?.googleLogin
            state.instaLogin = action.payload?.instaLogin
            state.facebookLogin = action.payload?.facebookLogin
            state.tiktokLogin = action.payload?.tiktokLogin
            state.integrationSettings = action.payload?.integrationSettings
            state.settings = action.payload?.settings
            state.isLifetimeAccess = action.payload?.isLifetimeAccess
            state.isAdmin = action.payload?.isAdmin
            state.planId = action.payload?.planId
            state.planExpiry = action.payload?.planExpiry
            state.volume = action.payload?.volume
            state.priceId = action.payload?.priceId
        },
        setTempdetail(state, action) {
            state.tempDetails = action.payload
        },
        clearTemplateList(state) {
            state.template_list = null
            state.template_list_loaded = false
        },
        setFilteredPlan(state, action) {
            state.filteredPlan = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(doGetTemplateById.fulfilled, (state, action) => {
            state.template_list = action?.payload
            state.template_list_loaded = true
        })
        .addCase(getVideoById.pending, (state, action) => {
            state.videoDetailsLoading = true
        })
        .addCase(getVideoById.fulfilled, (state, action) => {
            state.videoDetails = action.payload as any
            state.videoDetailsLoading = false
        })
    },
})

export const { setUser, setTempdetail, clearTemplateList, setFilteredPlan } = userSlice.actions
export default userSlice.reducer
