import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'

export type CommonState = {
    currentRouteKey: string
    creditErrors: ICreditsError
}

interface ICreditsError {
    remainingCredits: number | null;
    requiredCredits: number | null;
    isOpenModal: boolean;
    videoId: string | null;
  }
  

export const initialState: CommonState = {
    currentRouteKey: '',
    creditErrors: {
        remainingCredits: null,
        requiredCredits: null,
        isOpenModal: false,
        videoId: null
    }
}

export const commonSlice = createSlice({
    name: `${SLICE_BASE_NAME}/common`,
    initialState,
    reducers: {
        setCurrentRouteKey: (state, action: PayloadAction<string>) => {
            state.currentRouteKey = action.payload
        },
        setCreditErrors: (state, action) => {
            state.creditErrors = action.payload
        }
    },
})

export const { setCurrentRouteKey, setCreditErrors } = commonSlice.actions

export default commonSlice.reducer
