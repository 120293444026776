import {  SettingsType } from '@/store'
import ApiService from './ApiService'

export async function apiGetAccountSettingData<T>() {
    return ApiService.fetchData<T>({
        url: '/account/setting',
        method: 'get',
    })
}

export async function apiGetAccountSettingIntegrationData<T>() {
    return ApiService.fetchData<T>({
        url: '/account/setting/integration',
        method: 'get',
    })
}

export async function apiGetUserAccount<T>() {
    return ApiService.fetchData<T>({
        url: '/users/account',
        method: 'get',
    })
}

export async function apiUpdateSettings<T>(data: SettingsType) {
    return ApiService.fetchData<T>({
        url: '/users/settings',
        method: 'put',
        data
    })
}

export async function apiGetAccountSettingBillingData<T>() {
    return ApiService.fetchData<T>({
        url: '/payment/billing',
        method: 'get',
    })
}

export async function apiGetAccountInvoiceData<
    T,
    U extends Record<string, unknown>
>(params: U) {
    return ApiService.fetchData<T>({
        url: '/account/invoice',
        method: 'get',
        params,
    })
}

export async function apiGetAccountLogData<
    T,
    U extends Record<string, unknown>
>(data:U) {
    return ApiService.fetchData<T>({
        url: `/activities?platform=${data.filter}&type=${data.type}&skip=${data.activityIndex}&limit=${data.limit}`,
        method: 'get',
    })
}

export async function apiGetAccountFormData<T>() {
    return ApiService.fetchData<T>({
        url: '/account/form',
        method: 'get',
    })
}

export async function apiAllowSchedule<T>(data: {
    value: boolean;
    field: string;
}) {
    return ApiService.fetchData<T>({
        url: '/users/allowScheduling',
        method: 'put',
        data
    })
}
export async function apiUpdateUser<
    T,
    U extends Record<string, unknown>
>(data: U) {
    return ApiService.fetchData<T>({
        url: '/users/removeSocialPlatform',
        method: 'put',
        data
    })
}

export async function apiUpdateUserSettings<
    T,
    U extends Record<string, unknown>
>(data: U) {
    return ApiService.fetchData<T>({
        url: '/users/settings',
        method: 'put',
        data
    })
}

export async function apiCreateTemplates<
    T,
    U extends Record<string, unknown>
>(data: U) {
    return ApiService.fetchData<T>({
        url: '/users/createTemplate',
        method: 'post',
        data
    })
}

export async function apiGetTemplateDetails<T>(id: string) {
    return ApiService.fetchData<T>({
        url: `/users/fetchTemplate/${id}`,
        method: 'get',
    });
}

export async function apiCheckSpecialUserCodeUsed<
    T,
    U extends Record<string, unknown>
>(data: U) {
    return ApiService.fetchData<T>({
        url: '/users/special-user-code',
        method: 'post',
        data
    })
}
