import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'
import { apiGetPaymentProducts } from '@/services/PaymentServices'
import { StripeProduct } from '@/@types/common';

export interface IProductsState {
    products_list: StripeProduct[];
    products_list_loaded: boolean;
}


const initialState: IProductsState = {
    products_list: [],
    products_list_loaded: false
}

export const SLICE_NAME = 'products'



export const doGetProductsList = createAsyncThunk<StripeProduct[], void>(
    'payment/products',
    async () => {
        const response = await apiGetPaymentProducts()
        return response.data as StripeProduct[]
    }
)

const productSlice = createSlice({
    name: `${SLICE_BASE_NAME}/payment`,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(doGetProductsList.fulfilled, (state, action) => {
            state.products_list = action.payload
            state.products_list_loaded = true
        })
    },
})

export const { } = productSlice.actions
export default productSlice.reducer
