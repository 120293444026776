export type AppConfig = {
  apiPrefix: string
  authenticatedEntryPath: string
  unAuthenticatedEntryPath: string
  tourPath: string
  locale: string
  enableMock: boolean
}

const appConfig: AppConfig = {
  apiPrefix:  import.meta.env.VITE_BE_BASE_URL, // '/api'
  authenticatedEntryPath: '/app/videos',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/app/account/kyc-form',
  locale: 'en',
  enableMock: false, // true
}

export default appConfig
