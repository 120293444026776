import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { setCreditErrors, setUser, useAppDispatch } from '@/store';
import { apiUploadShorts } from '@/services/AuthService';
import { setVideoProgress } from '@/views/videos/store';
import useAuth from './useAuth';
import { Notification, toast } from '@/components/ui';
import { apiGetUserAccount } from '@/services/AccountServices';

const useGenerateVideo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authenticated } = useAuth();

  const [videoUrl, setVideoUrl] = useState<string | null>(null); // Store video URL state

  const handleCreditsError = useCallback(
    (remainingCredits: number, requiredCredits: number, videoId: string) => {
    dispatch(setCreditErrors({
      remainingCredits,
      requiredCredits: +requiredCredits?.toFixed(3),
      isOpenModal: true,
      videoId: videoId
    }));
  }, [dispatch]);

  const handleLiveVideoError = useCallback(() => {
    toast.push(
      <Notification title="Generate Shorts">
        Sorry, cannot generate shorts from a live YouTube video.
      </Notification>
    );
  }, []);

  useEffect(() => {
    const url = new URLSearchParams(window.location.search).get('videoUrl') || localStorage.getItem('videoUrl');
    setVideoUrl(url);
  }, []);

  const fetchVideo = useCallback(async (videoUrl: string) => {
    const videoId = videoUrl.split('&')[0];
    try {
      const response: any = await apiUploadShorts({ videoUrl: `/shorts/youtube/${videoId}` });
      dispatch(setVideoProgress(response?.data?.progress));
      const { data }: any = await apiGetUserAccount()
      dispatch(setUser(data?.data))
      navigate(`/app/videos/${response?.data?.videoId}`);
      localStorage.removeItem('videoUrl');
    } catch (error: any) {
      console.log('Error uploading video:', error);
      const responseError = error?.response?.data;
      const { remainingCredits, requiredCredits, liveVideo } = responseError || {};

      if (remainingCredits || requiredCredits) {
        handleCreditsError(+remainingCredits, +requiredCredits, videoId);
      }

      if (liveVideo) {
        handleLiveVideoError();
      }
    }
  }, []);

  useEffect(() => {
    if (videoUrl && authenticated) {
      fetchVideo(videoUrl);
    }
  }, [authenticated, videoUrl]);
};

export default useGenerateVideo;
