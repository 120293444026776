import Card from '@/components/ui/Card'
import Button from '@/components/ui/Button'
import { Select } from '@/components/ui'
import React, { useEffect, useMemo, useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import { HiOutlineClock } from 'react-icons/hi'
import { useAppSelector } from '@/store'
import { Feature, Pricing, StripeProduct } from '@/@types/common'
import { SingleValue } from 'react-select'
import HeaderExtraContent from './HeaderExtraContent'

interface PlanInterace {
    product: StripeProduct
    planId: String
    handleSubscriptionPayment: (
        planId: string,
        priceId: string
    ) => Promise<void>
    billingProducts: StripeProduct[]
}

interface PricingOption {
    value: number | 'unlimited'
    label: string
    price: number | string
    priceId: string
}

interface PlanData {
    planId?: string
    priceId?: string
    volume?: number
    header: string
    price: number
    priceUnit: string
    description: string
    features: Feature[]
    buttonLabel: string
    buttonVariant: string
    buttonSize: string
    platforms?: string[]
}

const Plan: React.FC<PlanInterace> = ({
    product,
    planId,
    handleSubscriptionPayment,
    billingProducts,
}) => {

    const isLifetimeAccess = useAppSelector((state) => state.auth.user?.isLifetimeAccess)

    const defaultPrice = product.pricingList.find(
        (price: Pricing) => price.isDefault
    )
    // const pricingListSorted = product.pricingList.sort(
    //     (a: { volume: number }, b: { volume: number }) => a.volume - b.volume
    // )
    const pricingListSorted = [...product.pricingList]?.sort(
        (a: { volume: number | null }, b: { volume: number | null }) => {
            return (a.volume || 0) - (b.volume || 0);
        });

    const priceId = useAppSelector((state) => state.auth.user?.priceId)

    const pricingOptions: PricingOption[] = [
        ...pricingListSorted.map((item: any) => {
            return {
                value: item.volume,
                label:
                    item.volume === 'unlimited'
                        ? 'Unlimited'
                        : `${item.volume} minutes`,
                price: item.price,
                priceId: item.priceId,
            }
        }),
        // {
        //     value: 'Unlimited',
        //     label: 'Unlimited',
        //     price: 'Contact us for a quote',
        //     priceId: 'unlimited',
        // },
    ]

    const [selectedOption, setSelectedOption] = useState<PricingOption>(
        pricingOptions[0]
    )

    const [isSelectedOption, setisSelectedOption] = useState<PricingOption>()
    const [defaultSelectedOption, setDefaultSelectedOption] =
        useState<PricingOption>()
    const [billingCycle, setBillingCycle] = useState<string>('')

    const handleContactUs = () => {
        if (isSelectedOption?.value === 'unlimited') {
            window.open('https://forms.gle/ryA6umJyAsdnevCs7', '_blank')
            return
        }
    }
    const planButtonLabel = (): string => {
        if (isSelectedOption?.value === 'unlimited') {
            return 'Contact us'
        }
        const isNumber = (value: number | 'unlimited'): value is number =>
            typeof value === 'number'
        if (
            isSelectedOption &&
            isNumber(selectedOption?.value!!) &&
            isNumber(defaultSelectedOption?.value!!)
        ) {
            if (selectedOption?.value!! > defaultSelectedOption?.value!!) {
                return 'Upgrade Plan'
            } else if (
                selectedOption?.value!! < defaultSelectedOption?.value!!
            ) {
                return 'Downgrade'
            } else {
                return 'Current Plan'
            }
        }
        return 'Current Plan'
    }

    const isGreaterPlan = useMemo(() => {
        const selectedProduct = billingProducts?.find(
            (v) => v.productId === planId
        )
        const selectedProductName = selectedProduct?.name
        const currentProductName = product?.name

        const planComparison: any = {
            Pro: 'Downgrade',
            Free: 'Upgrade',
            Starter: currentProductName === 'Pro' ? 'Upgrade' : 'Downgrade',
        }

        return planComparison[selectedProductName!!] || 'Upgrade'
    }, [billingProducts, planId, product])

    const planData: PlanData = {
        header: product.name,
        price: selectedOption?.price
            ? (+selectedOption?.price as number)
            : (+pricingOptions[0].price as number),
        planId: defaultPrice?.priceId ?? '',
        priceUnit: '/ Month',
        description: product.description,
        features: product.features,
        buttonLabel:
            product.status === 'Not Available'
                ? product.status
                : product.productId === planId
                ? planButtonLabel()
                : isSelectedOption?.value === 'unlimited'
                ? 'Contact Us'
                : isGreaterPlan || 'Upgrade',
        buttonVariant: 'primary',
        buttonSize: 'sm',
        platforms:
            product.name === 'Free'
                ? [
                      '/img/thumbs/youtube-shorts.png',
                      '/img/voodoo/disabled-tiktok.png',
                      '/img/voodoo/disabled-instagram-reels.png',
                      '/img/voodoo/disabled-facebook.png',
                  ]
                : [
                      '/img/thumbs/youtube-shorts.png',
                      '/img/voodoo/tiktok.png',
                      '/img/voodoo/instagram-reels.png',
                      '/img/voodoo/facebook.png',
                  ],
        volume: product.pricingList[0].volume,
    }

    useEffect(() => {
        setTimeout(() => {
            let index = pricingOptions.findIndex(
                (option) => option.value === selectedOption?.value
            )
            index = index === -1 ? 0 : index
            setSelectedOption(pricingOptions[index])
            setBillingCycle(product.interval)
        }, 0)

        const defaultSelectedValue = pricingOptions.find(
            (v) => v.priceId === priceId
        )
        setDefaultSelectedOption(defaultSelectedValue || pricingOptions[0])
    }, [priceId, product.interval])

    const handleSelectChange = (selected: SingleValue<PricingOption>) => {
        if (selected) {
            setisSelectedOption(selected)
            setSelectedOption(selected)
            if (
                `${selected.price}` !== 'Contact us for a quote' &&
                typeof selected.price === 'number'
            ) {
                planData.price = +selected.price.toFixed(0)
            }
        }
    }

    const handlePlanSubscription = () => {
        handleContactUs()
        if (planData.buttonLabel !== 'Current Plan') {
            handleSubscriptionPayment(
                product.productId ?? '',
                selectedOption?.priceId ?? ''
            )
        }
    }

    const PricingDialogButton = () => {
        return (
            <Button
                size={planData.buttonSize as any}
                variant={
                    ['Not Available'].includes(planData.buttonLabel)
                        ? 'default'
                        : (planData.buttonVariant as any)
                }
                className={`w-full`}
                disabled={['Not Available', 'Current Plan'].includes(
                    planData.buttonLabel
                )}
                onClick={handlePlanSubscription}
            >
                {planData.buttonLabel}
            </Button>
        )
    }
    return (
        <Card
            header={planData.header}
            headerExtra={
                (billingCycle === 'Yearly' || billingCycle === 'Monthly') ? (
                    <HeaderExtraContent
                        billingCycle={billingCycle}
                        selectedOptionPrice={selectedOption.price}
                        productName={product.name}
                        planData={planData}
                    />
                ) : billingCycle === 'Life Time' && (
                    <span className="flex items-center">
                        <h5 className="flex gap-1 items-center">
                            {isLifetimeAccess &&<span className="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">Charged Once</span>}
                            <span className="text-xl">${planData.price}</span>
                        </h5>
                    </span>
                )
            }
            className="!w-full lg:!w-96"
        >
            <div className="flex flex-col justify-between">
                <h6 className="mb-4">{planData.description}</h6>
                <div className="flex flex-col gap-2">
                    {planData.features.map((feature: Feature, index) => (
                        <div className="flex items-center gap-2" key={index}>
                            {feature.name.startsWith('! ') ? (
                                <IoClose className="text-red-500" />
                            ) : (
                                <FaCheck className="text-primary" />
                            )}
                            <p>{feature.name.replace('! ', '')}</p>
                        </div>
                    ))}
                         {isLifetimeAccess && 
                         <div className="flex items-center gap-2">
                                <FaCheck className="text-primary" />
                            <p>{`${selectedOption.value} Minutes / Every Month`}</p>
                            <span className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Forever</span>
                        </div>}
                </div>

                {/* {planData.platforms && (
                    <div className="flex flex-row gap-2 mt-4">
                        {planData.platforms.map((platform, index) => (
                            <img
                                key={index}
                                src={platform}
                                alt="platform icon"
                                className="w-6 h-6"
                            />
                        ))}
                    </div>
                )} */}

                <div
                    className={`flex flex-col items-end mt-4 ${
                        product.pricingList.length > 1 && 'gap-2'
                    }`}
                >
                    {product.pricingList.length > 1 ? (
                        <Select
                            className="w-full"
                            size="sm"
                            isSearchable={false}
                            defaultValue={!!defaultSelectedOption?.value}
                            options={pricingOptions as PricingOption[]}
                            menuPlacement="auto"
                            placeholder={
                                defaultSelectedOption?.label!! ||
                                selectedOption?.price
                            }
                            onChange={(e: any) => handleSelectChange(e)}
                        />
                    ) : (
                        <div className="flex gap-2 items-center w-full mb-2 rounded-md p-2 border border-transparent">
                            <HiOutlineClock className="text-gray-600" />
                            <p>{`${planData.volume} minutes`}</p>
                        </div>
                    )}

                    <PricingDialogButton />
                </div>
            </div>
        </Card>
    )
}

export default Plan
