import { useMemo, lazy, Suspense, useEffect, useState } from 'react'
import Loading from '@/components/shared/Loading'
import { withErrorBoundary } from 'react-error-boundary'
import { useAppSelector, useAppDispatch, setCreditErrors } from '@/store'
import {
    setUser
} from '@/store'
import { apiGetUserAccount } from '@/services/AccountServices'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
} from '@/constants/theme.constant'
import useAuth from '@/utils/hooks/useAuth'
import useDirection from '@/utils/hooks/useDirection'
import useLocale from '@/utils/hooks/useLocale'
import { SignInCredential } from '@/@types/auth'
import useGenerateVideo from '@/utils/hooks/useGenerateVideo'
import PricingDialog from '@/views/voodoo-ui/PricingDialog/PricingDialog'
import FreeNotEnoughCreditsDialog from '@/views/ui-components/data-display/Cards/FreeNotEnoughCreditsDialog'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
    const dispatch = useAppDispatch()
    const layoutType = useAppSelector((state) => state.theme.layout.type)
    const [loading, setLoading] = useState(false)
    const storedCreditsError = useAppSelector((state) => state.base.common.creditErrors)
    const { authenticated, signIn } = useAuth()
    const [isPricingDialogOpen, setIsPricingDialogOpen] = useState<boolean>(false)
    useGenerateVideo()

    const signInWithToken = async (token: string, lifeTimeAccess?: string) => {
        setLoading(true)
        await signIn({ token, lifeTimeAccess })
        setLoading(false)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
        const lifeTimeAccess = searchParams.get('lifeTimeAccess');
        if (token) {
            if(lifeTimeAccess){
                signInWithToken(token, lifeTimeAccess)
            }
            signInWithToken(token)
        }
    }, [])

    const getAccountDetails = async () => {
        const {data}:any = await apiGetUserAccount()
        dispatch(setUser(data.data))
    }

    useEffect(() => {
        if (authenticated) {
            getAccountDetails()
        }
    }, [authenticated])

    useDirection()

    useLocale()

    const AppLayout = useMemo(() => {
        if (authenticated) {
            return layouts[layoutType]
        }
        return lazy(() => import('./AuthLayout'))
    }, [layoutType, authenticated])

    if (loading) {
        return <div className="flex flex-auto flex-col h-[100vh]">
            <Loading loading={true} />
        </div>
    }

    if (storedCreditsError?.isOpenModal) {
        const videoLength =  Math.round(storedCreditsError?.requiredCredits!!)
        const userVolume =  Math.round(storedCreditsError?.remainingCredits!!)
        return (
           <>
           {isPricingDialogOpen && (
             <PricingDialog
                variant="notEnoughCredits"
                displayIcon={false}
                creditsError={{
                    remainingCredits: storedCreditsError?.remainingCredits!!,
                    requiredCredits: storedCreditsError?.requiredCredits!!,
                }}
                setCloseDialog={() => {
                    setIsPricingDialogOpen(false)
                    dispatch(setCreditErrors({
                        remainingCredits: undefined,
                        requiredCredits: undefined,
                        isOpenModal: false
                    }))
                }}
                isOpen={isPricingDialogOpen}
            />
           )}

            {storedCreditsError?.isOpenModal && (
                <FreeNotEnoughCreditsDialog
                freeDontHaveEnoughDialogOpen={storedCreditsError?.isOpenModal}
                handleClose={() => dispatch(setCreditErrors({
                    remainingCredits: undefined,
                    requiredCredits: undefined,
                    isOpenModal: false,
                    videoId: undefined
                }))}
                videoId={storedCreditsError?.videoId!!}
                creditsError={{
                    remainingCredits: userVolume!!,
                    requiredCredits: videoLength!!,
                }}
                handleOpenPricingModal={() => {
                    setIsPricingDialogOpen(true)
                    dispatch(setCreditErrors({
                        remainingCredits: +userVolume,
                        requiredCredits: +videoLength,
                        isOpenModal: false,
                        videoId: storedCreditsError?.videoId
                    }))
                }}
            />
            )}
           </>
        )
    }

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    )
}

const ComponentWithErrorBoundary = withErrorBoundary(Layout, {
    fallback: <div className="flex flex-auto flex-col h-[100vh]">
        <Loading loading={true} />
    </div>,
    onError(error, info) {
        console.log('error', error.message)
        if (error.message.includes('Failed to fetch dynamically imported module')) {
            // window.location.reload()
        }
    },
})

export default ComponentWithErrorBoundary
