import ApiService from "./ApiService";

//Api for Getting the details of all the listed stripe Products
export async function apiGetPaymentProducts<T>() {
    return ApiService.fetchData<T>({
        url: '/payment/products',
        method: 'get',
    })
}

//Api for Subscribing User to our products Plan
export async function apiPostPaymentSubscription<T, U extends Record<string, unknown>>(
    data: U
) {
    return ApiService.fetchData<T>({
        url: '/payment/subscription-account',
        method: 'post',
        data
    })
}

//Api for Deleting User's Subscribed Plan
export async function apiDeleteSubscription<T>() {
    return ApiService.fetchData<T>({
        url: '/payment/subscription-delete',
        method: 'delete'
    })
}



//Api for Creating Connected Stripe Account so that we can pay affiliation bonus payments
export async function apiPostPaymentAffilationAccount<T>(
) {
    return ApiService.fetchData<T>({
        url: '/payment/affilition-account',
        method: 'post',
    })
}

export async function apiGetPaymentAffilationAccount<T>(
) {
    return ApiService.fetchData<T>({
        url: '/payment/connect/stripe',
        method: 'get',
    })
}

//Api for Checking Affilated Stripe Connected Account and Getting its Details
export async function apiGetAffiliatedAccountData<T>(
) {
    return ApiService.fetchData<T>({
        url: '/payment/affiliation-status',
        method: 'get',
    })
}




