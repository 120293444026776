import Button from '@/components/ui/Button'
import { Dialog, Input } from '@/components/ui'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../affiliate/CryptoDashboard/store/index'
import { Slider } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { apiUploadShorts } from '@/services/AuthService'
import { setVideoProgress, useAppDispatch } from '@/views/videos/store'
import { setUser } from '@/store'
import { apiGetUserAccount } from '@/services/AccountServices'
import { useAppDispatch as useRootAppDispatch } from '@/store'

interface IFreeNotEnoughCreditsDialog {
    freeDontHaveEnoughDialogOpen: boolean
    setFreeDontHaveEnoughDialogOpen?: (value: boolean) => void
    creditsError: { remainingCredits: number; requiredCredits: number }
    handleClose?: () => void
    handleOpenPricingModal?: () => void
    videoId?: string
    setIsPricingDialogOpen?: (value: boolean) => void
    handleGenerateClips?: (value?: any) => void
    setCreditsError?: (value?: any) => void
}

const FreeNotEnoughCreditsDialog: React.FC<IFreeNotEnoughCreditsDialog> = ({
    freeDontHaveEnoughDialogOpen,
    setFreeDontHaveEnoughDialogOpen,
    creditsError,
    handleClose,
    videoId,
    setIsPricingDialogOpen,
    handleOpenPricingModal,
    handleGenerateClips,
    setCreditsError
}) => {
    const userId = useAppSelector((state) => state.auth.user._id)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const dispatchRoot = useRootAppDispatch()

    // State to track the range value
    const [rangeValue, setRangeValue] = useState<[number, number]>([0, 30])
    const [selectedVideoTime, setSelectedVideoTime] = useState<{
        startTime: number | null
        endTime: number | null
    } | null>(null)
    const [isGetClipLoading, setIsGetClipLoading] = useState<boolean>(false)

    const videoLength = creditsError?.requiredCredits || 0
    const userVolume = creditsError?.remainingCredits || 0

    const stepsCondition =
        userVolume <= 1 && videoLength > 1 && videoLength < 1.9
    const isRangeEqual = rangeValue[0] === rangeValue[1]

    useEffect(() => {
        if (userVolume) setRangeValue([0, userVolume])
    }, [userVolume])

    // Handler to ensure the range doesn't exceed user's volume but can span the video length
    const handleSliderChange = (value: number[]) => {
        const [start, end] = value
        const maxRange = userVolume ?? 0

        const adjustedEnd = Math.min(start + maxRange, videoLength) // Ensure end doesn't exceed video length

        // If the difference exceeds user volume, adjust the end value
        if (end - start > maxRange) {
            setRangeValue([start, adjustedEnd])
            setSelectedVideoTime({
                startTime: start,
                endTime: adjustedEnd,
            })
        } else {
            setRangeValue(value as [number, number]) // Cast to the desired tuple type
            setSelectedVideoTime({
                startTime: start,
                endTime: end,
            })
        }
    }

    const generateClips = async () => {
        if (!videoId && !creditsError) return;
        setIsGetClipLoading?.(true)
        console.log('time range', {
            ...(creditsError?.requiredCredits && {
                data: {
                    video_start_time: selectedVideoTime?.startTime ?? 0,
                    video_end_time:
                        selectedVideoTime?.endTime ??
                        creditsError?.remainingCredits,
                },
            }),
        })
        try {
            const response = await apiUploadShorts({
                videoUrl: `/shorts/youtube/${videoId}`,
                ...(creditsError?.requiredCredits && {
                    data: {
                        video_start_time: selectedVideoTime?.startTime ?? 0,
                        video_end_time:
                            selectedVideoTime?.endTime ??
                            creditsError?.remainingCredits,
                    },
                }),
            })
            dispatch(setVideoProgress(response?.data?.progress))
            const { data }: any = await apiGetUserAccount()
            dispatchRoot(setUser(data?.data))
            navigate(`/app/videos/${response?.data?.videoId}`)

            // Close the dialog after generating clips
            handleCloseDialog()
        } catch (e) {
            console.log('Error in generate clips', e)
            setIsGetClipLoading?.(false)
        }
    }

    useEffect(() => {
        if (freeDontHaveEnoughDialogOpen) {
            setSelectedVideoTime(null)
            setRangeValue([0, userVolume])
        }
    }, [freeDontHaveEnoughDialogOpen, userVolume])

    const handleCloseDialog = () => {
        setFreeDontHaveEnoughDialogOpen?.(false)
        handleClose?.()
        setSelectedVideoTime(null)
        setIsGetClipLoading?.(false)
        setCreditsError?.(null)
    }

    const handlePricingDialog = () => {
        setIsPricingDialogOpen?.(true)
        setFreeDontHaveEnoughDialogOpen?.(false)
        handleOpenPricingModal?.()
        setIsGetClipLoading?.(false)
        setCreditsError?.(null)
    }

    const handleGeneratingClips = async () => {
        setIsGetClipLoading(true)
        const value = {
            ...(creditsError?.requiredCredits && {
                data: {
                    video_start_time: selectedVideoTime?.startTime ?? 0,
                    video_end_time: selectedVideoTime?.endTime ?? creditsError?.remainingCredits
                }
            })
        }
        await handleGenerateClips?.(creditsError?.requiredCredits ? value : undefined)
        setFreeDontHaveEnoughDialogOpen?.(false)
        setCreditsError?.(null)
        setIsGetClipLoading(false)
    }

    return (
        <div>
            <Dialog
                isOpen={freeDontHaveEnoughDialogOpen}
                onClose={handleCloseDialog}
                onRequestClose={handleCloseDialog}
            >
                <div className="flex flex-col gap-8 select-none">
                    <div className="flex flex-col gap-2">
                        <h5 className="pb-4">You Don't Have Enough Credits</h5>
                        <p className="text-base">
                            Your video is longer than {userVolume} minutes,
                            which exceeds the limit for your current plan.
                            Please choose up to {userVolume} minutes of the
                            video or consider upgrading your plan.
                        </p>
                    </div>
                    {!!userVolume && (
                        <div className="flex flex-col gap-8">
                            <div className="mb-4">
                                <Slider
                                    range={{ draggableTrack: true }}
                                    value={rangeValue}
                                    max={videoLength} // Video's total length
                                    onChange={handleSliderChange} // Update here
                                    tooltip={{
                                        open: true,
                                        placement: 'bottom',
                                    }}
                                    styles={{
                                        track: {
                                            // background: 'transparent',
                                        },
                                        tracks: {
                                            background: `#0069ff`,
                                        },
                                        rail: {
                                            backgroundColor: 'gray',
                                        },
                                    }}
                                    step={1}
                                />
                            </div>
                        </div>
                    )}
                    <div className="flex flex-row gap-2 justify-between">
                        <Button
                            size="sm"
                            variant="default"
                            onClick={handlePricingDialog}
                        >
                            Upgrade
                        </Button>
                        <Button
                            size="sm"
                            variant="primary"
                            onClick={handleGenerateClips ? handleGeneratingClips : generateClips}
                            disabled={!userVolume || isRangeEqual}
                            loading={isGetClipLoading}
                        >
                            Get Clips
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default FreeNotEnoughCreditsDialog
